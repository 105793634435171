<script setup>
import { ref, watch } from "vue";
import { useRoute } from "vue-router";

import authStore from "@/stores/auth";

import MenuItemV2 from "@/components/navBarV2/MenuItemV2.vue";
import MintyModal from "@/components/bookingsView/MintyModal.vue";

const burgerMenuOpen = ref(false);

const route = useRoute();

import { inject } from "vue";

const feedback = inject("feedback");

const triggerFeedback = async () => {
  const form = await feedback.createForm();
  form.appendToDom();
  form.open();
};

watch(
  () => route.fullPath,
  () => {
    burgerMenuOpen.value = false;
  }
);

const logoutSession = async () => {
  await authStore().logout();
};

const modalSentry = ref(false);

//Constants with roles and categories for more comprehensible code
const rolAdmin = 1;
const rolOwner = 2;
const rolManager = 3;
const rolAchiever = 4;
const rolGuest = 5;
const rolApi = 6;
const rolExternal = 7;

const catGeneral = 1;
const catCleaning = 2;
const catMaintenance = 3;
const catReception = 4;
const catGuestAttention = 5;
const catClientAttention = 6;
</script>

<template>
  <div
    class="bg-blue-active h-[80px] lg:h-full flex flex-row lg:flex-col px-6 lg:px-0 py-4 gap-4 fixed top-0 left-0 w-full lg:w-[220px] lg:max-w-[220px] z-[90]"
  >
    <img class="w-1/3 mx-auto hidden lg:block" src="/common/main-logo.png" fetchpriority="high" rel="preload" />

    <img
      class="h-12 w-fit block lg:hidden max-w-[174px]"
      fetchpriority="high"
      rel="preload"
      src="/common/main-logo-mobile.png"
      alt="main logo"
    />

    <!-- BURGER MENU -->
    <button
      @click="burgerMenuOpen = !burgerMenuOpen"
      class="visible ml-auto lg:hidden flex flex-col gap-1 h-full aspect-square justify-center items-center p-2"
    >
      <Transition name="tr-bounce" mode="out-in">
        <font-awesome-icon v-if="!burgerMenuOpen" icon="bars" class="text-white h-full w-full" />

        <font-awesome-icon v-else icon="times" class="text-white h-full w-full" />
      </Transition>
    </button>

    <!-- MENU ITEMS -->
    <div
      :class="[
        'flex flex-col overflow-x-hidden overflow-y-auto mb-[120px] transition-all duration-300',

        'fixed top-[80px] bottom-[0px] pt-8 -z-20 gap-y-2 items-center left-0 inset-0 w-full bg-blue-active',
        'lg:max-w-full lg:h-fit lg:pt-4 lg:px-2 lg:relative lg:bg-transparent lg:mt-0 lg:items-start lg:z-0 lg:gap-y-1 lg:top-0',

        burgerMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0 pointer-events-none md:pointer-events-auto',
      ]"
    >
      <!-- If allow is empty it will be shown to all roles/categories -->

      <!-- Dashboard -->
      <MenuItemV2
        :title="'dashboard'"
        :image="'smartphone'"
        :url="'/panel'"
        :envRedirect="'core'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
          [rolManager, '*'],
          [rolAchiever, '*'],
        ]"
      />

      <!-- Apartments -->
      <MenuItemV2
        :title="'properties'"
        :image="'house'"
        :url="'/apartments'"
        :envRedirect="'main'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
          [rolManager, '*'],
          [rolAchiever, '*'],
        ]"
      />

      <!-- Current bookings -->
      <MenuItemV2
        :title="'current-bookings'"
        :image="'tail-glass'"
        :url="'/current-bookings'"
        :envRedirect="'main'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
          [rolManager, '*'],
          [rolExternal, catReception],
        ]"
      />

      <!-- Bookings -->
      <MenuItemV2
        :title="'bookings'"
        :image="'calendar'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
          [rolManager, '*'],
        ]"
      >
        <MenuItemV2
          :title="'list'"
          :url="'/bookings'"
          :envRedirect="'main'"
        />

        <MenuItemV2
          :title="'clients_multicalendar'"
          :url="'/bookings/multicalendar'"
          :envRedirect="'main'"
        />
      </MenuItemV2>

      <!-- Checkins -->
      <MenuItemV2
        :title="'checkins'"
        :image="'key'"
        :url="'/checkins'"
        :envRedirect="'main'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
          [rolManager, '*'],
        ]"
      />

      <!-- Cleanings -->
      <MenuItemV2
        :title="'cleanings'"
        :image="'broom'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
          [rolManager, '*'],
          [rolExternal, catCleaning],
        ]"
      >
        <MenuItemV2 :title="'cleanings-today'" :url="'/cleaning?day=today'" :envRedirect="'core'" />

        <MenuItemV2 :title="'cleanings-tomorrow'" :url="'/cleaning?day=tomorrow'" :envRedirect="'core'" />
      </MenuItemV2>

      <!-- Tickets -->
      <MenuItemV2
        :title="'tickets'"
        :image="'ticket'"
        :url="'/new-tickets'"
        :envRedirect="'core'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
          [rolManager, '*'],
        ]"
      />

      <!-- Maintenance -->
      <MenuItemV2
        :title="'maintenance'"
        :image="'wrench-white'"
        :url="'/maintenance'"
        :envRedirect="'core'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
          [rolManager, '*'],
        ]"
      />

      <!-- Communications -->
      <MenuItemV2
        :title="'communications'"
        :image="'chat'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
          [rolManager, '*'],
        ]"
      >
        <MenuItemV2
          :title="'chats'"
          :url="'/chats'"
          :envRedirect="[
            { env: 'prod', sendTo: 'main', },
            { env: 'dev', sendTo: 'main', },
            { env: 'local', sendTo: 'main', },
          ]"
        />

        <MenuItemV2
          :title="'otas'"
          :url="'/communications'"
          :envRedirect="'main'"
          :showIn="['local', 'dev']"
        />

      </MenuItemV2>

      <!-- Financial -->
      <MenuItemV2
        :title="'financial'"
        :image="'bill'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
          [rolManager, '*'],
        ]"
      >
        <MenuItemV2 :title="'financial-conciliation'" :url="'/financial/conciliation'" :envRedirect="'core'" />

        <MenuItemV2 :title="'financial-billing'" :url="'/financial/billing/menu'" :envRedirect="'core'" />

        <MenuItemV2 :title="'financial-collections'" :url="'/financial/collection'" :envRedirect="'core'" />
      </MenuItemV2>

      <!-- Users -->
      <MenuItemV2
        :title="'users'"
        :image="'user'"
        :url="'/users'"
        :allow="[[rolAdmin,'*'], [rolOwner,'*'], [rolManager,'*']]"
        :envRedirect="'main'"
      />

      <!-- Settings -->
      <MenuItemV2
        :title="'settings'"
        :image="'levels'"
        :allow="[
          [rolAdmin, '*'],
          [rolOwner, '*'],
        ]"
      >
        <MenuItemV2 :title="'services_guest'" :url="'/config/services-guest'" :envRedirect="'main'" />

        <MenuItemV2 :title="'auto_messages'" :url="'/config/auto-messages'" :envRedirect="'main'" />

        <MenuItemV2 :title="'chats'" :url="'/config/templates'" :envRedirect="'main'" />

        <MenuItemV2 :title="'org_config'" :url="'/config/data'" :envRedirect="'main'" />

        <MenuItemV2 :title="'groups'" :url="'/config/areas'" :envRedirect="'main'" />

        <MenuItemV2 :title="'clients'" :url="'/config/clients'" :envRedirect="'main'" />

        <MenuItemV2 :title="'providers'" :url="'/config/providers'" :envRedirect="'main'" />

        <MenuItemV2 :title="'booking_sync'" :url="'/config/bookings-import'" :envRedirect="'main'" />
      </MenuItemV2>

      <!---------------- Sections for clients ---------------------->
      <!-- My Properties -->
      <MenuItemV2
        :title="'properties'"
        :image="'house'"
        :url="'/client-home'"
        :envRedirect="'core'"
        :allow="[[rolExternal, catClientAttention]]"
      />

      <!-- Clients multicalendar -->
      <MenuItemV2
        :title="'clients_multicalendar'"
        :url="'/bookings/multicalendar-clients/clients'"
        :image="'calendar'"
        :envRedirect="'core'"
        :allow="[
          [rolExternal, catClientAttention],
          [rolExternal, catCleaning],
        ]"
      />

      <!-- Clients multicalendar -->
      <MenuItemV2
        :title="'clients_financial'"
        :url="'/client/financial'"
        :image="'bill'"
        :envRedirect="'core'"
        :allow="[[rolExternal, catClientAttention]]"
      />

      <!-- Clients KPI Report -->
      <MenuItemV2
        :title="'clients_kpi'"
        :url="'/client/kpi-report'"
        :image="'kpi'"
        :envRedirect="'core'"
        :allow="[[rolExternal, catClientAttention]]"
      />

      <!-- Clients Download -->
      <MenuItemV2
        :title="'downloads'"
        :url="'/client/download'"
        :image="'download'"
        :envRedirect="'core'"
        :allow="[[rolExternal, catClientAttention]]"
      />
    </div>

    <div
      v-if="authStore().user"
      :class="[
        'w-full fixed lg:absolute bottom-0 pb-4 flex flex-col gap-1 items-center justify-center left-0 pt-4 transition-all duration-300 bg-blue-active',
        burgerMenuOpen ? 'translate-x-0' : '-translate-x-full lg:translate-x-0',
      ]"
    >
      <img src="/common/user-2.png" class="h-[24px] aspect-square" />

      <span class="font-semibold text-white">{{ authStore().user.full_name }}</span>

      <button
        @click="logoutSession()"
        class="flex justify-end items-center py-1 px-3 bg-blue-menu hover:bg-blue-secondary transition ease-in-out cursor-pointer rounded-lg uppercase text-white font-bold text-xs tracking-wide"
      >
        <span>{{ $t("menu.logout") }}</span>

        <img src="/common/logout.png" class="h-6 ml-2" />
      </button>

      <button
        @click="triggerFeedback()"
        id="show-sentry-feedback"
        class="mt-6 flex justify-end items-center py-1 px-3 bg-blue-menu hover:bg-blue-secondary transition ease-in-out cursor-pointer rounded-lg uppercase text-white font-bold text-xs tracking-wide"
      >
        <span>{{ $t("menu.report-bug") }}</span>
        <img src="/common/anti-bug.png" class="h-6 ml-2" />
      </button>
    </div>
    <!-- <MintyModal
      :showIf="modalSentry"
      @onClose="modalSentry = false"
      :headerColor="'bg-red-500'"
      :modalTitle="$t('menu.report-bug')"
      :customMaxWidth="'max-w-[850px]'"
    >
      <p class="w-2/3 text-center font-semibold m-auto" v-html="$t('menu.explain-sentry')"></p>

      <button
        @click="sendManualReplay"
        class="max-w-1/2 m-auto relative h-[40px] mt-4 whitespace-nowrap hover:scale-[101%] text-white font-semibold py-1 md:py-2 md:px-8 rounded-md px-8 transform flex gap-2 justify-center items-center bg-blue-active min-h-[48px] md:min-h-[40px]"
      >
        {{ $t("apartment-details.confirm") }}
      </button>
    </MintyModal> -->
  </div>
</template>

<style lang="scss" scoped>
::-webkit-scrollbar {
  appearance: none;
}

::-webkit-scrollbar:vertical {
  @apply w-[5px];
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-blue-template bg-opacity-20 rounded-md hover:bg-blue-cta transition duration-200;
}
</style>
